import { Entity } from 'src/app/shared/models/entity.model';

import { inject, Injectable } from '@angular/core';
import { select } from '@ngneat/elf';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';

import { AuthProps } from './auth.model';
import { authStore } from './auth.store';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AuthRepoService {
  private authStateService = inject(OktaAuthStateService);
  private oktaAuth = inject(OKTA_AUTH);

  readonly loggedIn$ = authStore.pipe(select((state) => state.loggedIn));
  readonly user$ = authStore.pipe(select((state) => state.user));

  // eslint-disable-next-line max-lines-per-function
  constructor() {
    this.authStateService.authState$.pipe(untilDestroyed(this)).subscribe(
      // eslint-disable-next-line max-lines-per-function
      ({
        accessToken: accessTokenData,
        idToken: idTokenData,
        isAuthenticated: loggedIn,
      }) => {
        if (loggedIn && accessTokenData && idTokenData) {
          const {
            accessToken,
            claims: { email, firstName, lastName, organization_access },
          } = accessTokenData;

          const { idToken } = idTokenData;

          this.setAuthProps({
            loggedIn,
            user: {
              accessToken,
              idToken,
              email,
              firstName: firstName as string,
              lastName: lastName as string,
              organization: organization_access as string,
            },
          });
        } else {
          this.setAuthProps({ loggedIn: false, user: undefined });
        }
      },
    );
  }

  logout(): void {
    this.setAuthProps({ loggedIn: false, user: undefined });
    this.oktaAuth.signOut();
  }

  getUserInfo(): Partial<Entity> & { organization?: string } {
    const auth = authStore.getValue();

    const createdBy = `${auth.user?.firstName ?? ''} ${auth.user?.lastName ?? ''}`;
    const organization = auth.user?.organization ?? '';

    return {
      createdBy: createdBy,
      createdAt: new Date().toISOString(),
      organization,
    };
  }

  private setAuthProps(value: AuthProps) {
    authStore.update((state) => ({
      ...state,
      ...value,
    }));
  }
}
